import type { LinkProps } from '@chakra-ui/next-js'
import { Link } from '@chakra-ui/next-js'

export type GrandLinkProps = LinkProps & {
  isExternal?: boolean
}

export const GrandLink = ({ isExternal, ...props }: GrandLinkProps) => {
  return (
    <Link
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      {...props}
    />
  )
}
