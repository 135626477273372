'use client'
import type { FC, ReactElement } from 'react'
import { useEffect, useState } from 'react'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Flex, VStack } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import ContentContainer from '../storefront/layout/ContentContainer'
import { useOverrideComponentPropsContext } from '../lib/override-component-props'
import GrandText from './GrandText'
import { GrandDivider } from './GrandDivider'
import GrandRibbon from './GrandRibbon'
import GrandNextLink from './GrandNextLink'
import GrandHeading from './GrandHeading'
import ResponsiveTextBox from './ResponsiveTextBox'
import type { DynamicHeroImageProps } from './DynamicHeroImage'
import DynamicHeroImage from './DynamicHeroImage'
import GrandOverlay from './GrandOverlay'
import type { GrandAspectRatioProps } from './GrandAspectRatio'
import GrandAspectRatio from './GrandAspectRatio'

interface HeroContent {
  title?: string
  sub?: string | ReactElement | null
  cta?: {
    label: string
    path: string
    isExternal?: boolean
  }
  slogan?: string
  alt?: string
}

export type HeroProps = Omit<Partial<GrandAspectRatioProps<FlexProps, 'w'>>, 'content'> & {
  content: HeroContent
  priority?: boolean
  imgUrl?: string
  mobileImgUrl?: string | null
  ribbonText?: string
  headingTag?: any
  styleProps?: HeroStyleProps
}

export interface HeroStyleProps {
  content?: any
  header?: any
  isDividerVisible?: boolean
  imageProps?: Partial<DynamicHeroImageProps>
  imageOverlayBox?: Partial<BoxProps>
  mobileLayout?: 'DEFAULT' | 'COMPACT'
}

const Hero: FC<HeroProps> = (_props) => {
  const mergedProps = useOverrideComponentPropsContext('Hero', _props)
  const {
    imgUrl,
    mobileImgUrl,
    priority,
    content,
    ribbonText,
    headingTag,
    styleProps = {
      content: {},
      header: {},
      isDividerVisible: true,
      mobileLayout: 'DEFAULT'
    },
    ...props
  } = mergedProps
  const [deviceImgUrl, setDeviceImgUrl] = useState(() => (mobileImgUrl ? null : imgUrl))

  useEffect(() => {
    if (mobileImgUrl && isMobileOnly) {
      setDeviceImgUrl(mobileImgUrl)
    } else {
      setDeviceImgUrl(imgUrl)
    }
    // eslint-disable-next-line -- Only obn mount
  }, [])

  return (
    <>
      {/* START compact mode */}
      <Flex
        className="hero__wrapper"
        direction="column"
        display={{
          base: styleProps.mobileLayout === 'COMPACT' ? 'flex' : 'none',
          lg: 'none'
        }}
        w="full">
        <GrandHeading
          className="hero__title"
          fontSize="2xl"
          headingTag="h1"
          textAlign="center"
          title={content.title || ''}
        />
        {styleProps.isDividerVisible ? <GrandDivider /> : null}
      </Flex>
      {/* END compact mode */}

      <GrandAspectRatio<FlexProps, 'w'>
        breakpoints={{
          base: '4:3',
          sm: '33:10'
        }}
        className="hero__wrapper"
        color="text.light"
        display={{
          base: styleProps.mobileLayout === 'COMPACT' ? 'none' : 'flex',
          lg: 'flex'
        }}
        mt={ribbonText ? 5 : 0}
        {...props}>
        {ribbonText ? <GrandRibbon ribbonText={ribbonText} /> : null}

        <GrandOverlay className="hero__overlay" />

        <ContentContainer
          alignItems="center"
          bottom={0}
          className="hero__content-container"
          direction="column"
          h="100%"
          justifyContent="center"
          left={0}
          position="absolute"
          px={{
            base: 2,
            md: 8
          }}
          right={0}
          spacing={6}
          textAlign="center"
          top={0}
          zIndex={2}
          {...styleProps.content}>
          <VStack
            alignSelf="center"
            spacing={{
              base: 1,
              md: 4
            }}>
            {content.slogan ? (
              <GrandText className="hero__slogan" fontSize={['md', 'lg']} textStyle="slogan">
                {content.slogan}
              </GrandText>
            ) : null}

            {content.title ? (
              <ResponsiveTextBox
                as={headingTag ? headingTag : 'h1'}
                className="hero__title"
                color="text.light"
                fontSize={['4xl', '5xl']}
                fontWeight="medium"
                w="100%"
                {...styleProps.header}>
                {content.title}
              </ResponsiveTextBox>
            ) : null}
            {styleProps.isDividerVisible && (content.title || content.sub) ? (
              <GrandDivider
                borderColor="text.light"
                borderWidth={1}
                className="hero__divider"
                opacity={1}
                w="40%"
              />
            ) : null}
          </VStack>

          {content.sub ? (
            <GrandText className="hero__sub" fontSize={['lg', 'xl']}>
              {content.sub}
            </GrandText>
          ) : null}

          {content.cta ? (
            <GrandNextLink
              aria-label={content.cta.label}
              className="hero__cta"
              href={content.cta.path}
              isButton
              isExternal={content.cta.isExternal}>
              {content.cta.label}
            </GrandNextLink>
          ) : null}
        </ContentContainer>

        {deviceImgUrl || imgUrl ? (
          <>
            {!deviceImgUrl && (
              <GrandOverlay
                className="hero__overlay"
                {...(deviceImgUrl ? {} : { backdropFilter: 'blur(10px)' })}
              />
            )}

            <DynamicHeroImage
              alt="hero"
              src={deviceImgUrl || imgUrl || ''}
              priority={priority}
              {...styleProps.imageProps}
              {...(deviceImgUrl
                ? {}
                : {
                    quality: 1,
                    sizes: '32vw'
                  })}
            />
          </>
        ) : null}
      </GrandAspectRatio>
    </>
  )
}

export default Hero
