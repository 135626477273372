import type { FC, MouseEvent } from 'react'
import type { LinkProps } from '@chakra-ui/next-js'
import { useOverrideComponentPropsContext } from '../lib/override-component-props'
import type { GrandLinkProps } from './GrandLink'
import { GrandLink } from './GrandLink'

export interface GrandNextLinkProps extends GrandLinkProps {
  href: string
  isExternal?: boolean
  isDisabled?: boolean
  isButton?: boolean
  stylesAsButton?: Omit<LinkProps, 'href'>
}

const GrandNextLink: FC<GrandNextLinkProps> = (_props) => {
  const mergedProps = useOverrideComponentPropsContext('GrandNextLink', _props)
  const { isButton, isExternal, isDisabled, stylesAsButton, href, ...props } = mergedProps

  const disabledProps = {
    bgColor: 'gray',
    borderColor: 'transparent',
    opacity: 1,
    color: 'darkGray',
    cursor: 'default',
    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
    }
  }

  const styles = {
    py: 2,
    px: 4,
    color: 'text.light',
    textAlign: 'center' as const,
    bg: 'primary',
    fontSize: 'sm',
    fontWeight: 'medium',
    transition: 'all 0.35s cubic-bezier(.08,.52,.52,1)',
    minW: 140,
    borderWidth: '1px',
    borderColor: 'primary',
    borderRadius: 'sm',
    _hover: {
      filter: 'brightness(1.1) contrast(100%)',
      _disabled: {
        bgColor: 'text.mild'
      }
    },
    _active: {
      filter: 'brightness(1.2) contrast(100%)',
      transform: 'scale(0.98)',
      _disabled: {
        transform: 'none'
      }
    },
    outlineOffset: 0,
    shadow: 'sm',
    ...stylesAsButton
  }

  // const normalizedHref =

  return (
    <GrandLink
      href={href}
      isExternal={isExternal}
      {...(isDisabled ? { ...disabledProps } : {})}
      _focus={{ outline: 'none' }}
      cursor="pointer"
      textDecoration="none!important"
      {...(isButton ? { ...styles } : {})}
      {...props}
    />
  )
}

export default GrandNextLink
