/**
 * Capitalizes the first character of a string, leaving the rest unchanged.
 *
 * @param str - The input string to be transformed.
 * @returns The string with the first character capitalized and the rest unchanged.
 *
 * @example
 * ```typescript
 * capsFirst("hello");  // "Hello"
 * capsFirst("WORLD");  // "WORLD"
 * capsFirst("tEsTiNg"); // "TEsTiNg"
 * ```
 */
export const capsFirst = function (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Converts a given string to sentence case.
 *
 * @param str - The input string to be transformed.
 * @returns The string with the first character capitalized and the rest in lowercase.
 *
 * @example
 * ```typescript
 * toSentenceCase("hello WORLD"); // "Hello world"
 * toSentenceCase("tEsTiNg");     // "Testing"
 * ```
 */
export const toSentenceCase = function (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
