import { type MinorUnit } from 'ecosystem'
import { DEFAULT_COUNTRY, VAT_RATES } from '../constants'
import { isNullable } from './general'

/**
 * Normalize country code to  alpha-2 format. Like `SE | NO`
 * @remarks
 * Different payment systems use different country codes. There are alpha-2 and alpha-3 formats.
 * For example: Sweden - `SE` (alpha-2) - `SWE` (alpha-3)
 * @see https://www.iban.com/country-codes
 * @param countryCode - string. Example: 'se' | 'SE' | 'Se' | 'SWE'
 * @example
 * ```ts
 * normalizeCountryCode('SWE') // SE
 * normalizeCountryCode('se') // SE
 * normalizeCountryCode('NOR') // NO
 * ```
 */
export const normalizeCountryCode = (countryCode: string): string => {
  const codes: Record<string, { correct: string; variants: string[] }> = {
    SE: {
      correct: 'SE',
      variants: ['SWE', 'SV']
    },
    NO: {
      correct: 'NO',
      variants: ['NOR']
    },
    FI: {
      correct: 'FI',
      variants: ['FIN']
    },
    DK: {
      correct: 'DK',
      variants: ['DNK']
    }
  }

  const resultCode = countryCode.toUpperCase()

  if (codes[resultCode]) {
    return resultCode
  }

  const foundCorrectCode = Object.values(codes).find((item) =>
    item.variants.includes(resultCode)
  )?.correct

  return foundCorrectCode ?? resultCode
}

/**
 * Get VAT (tax) rate in particular country
 * @remarks
 * If countryCode is not from the predefined list `VAT_RATES`, return value for Sweden
 * @param countryCode - 'SE' | 'NO' | 'FI' | 'DK' and so on
 */
export const getVatRateByCountryCode = (countryCode?: string | null): number => {
  if (isNullable(countryCode)) {
    return VAT_RATES[DEFAULT_COUNTRY]
  }

  return (
    VAT_RATES[normalizeCountryCode(countryCode) as keyof typeof VAT_RATES] ??
    VAT_RATES[DEFAULT_COUNTRY]
  )
}

/**
 * Convert number value into format acceptable for payment services: `value * 100`.
 * @remarks
 * All payment services require to use this format for number values, such as `price`, `taxRate` and so on
 * @example
 * ```ts
 * {
 *   unitPrice: createMinorUnit(5000), // 500000, a price for 5000.00 SEK,
 *   discountPercent: createMinorUnit(10), // 1000, a discount for 10.00%
 *   vatRate: createMinorUnit(25), // 2500, tax rate for 25.00%
 * }
 * ```
 */
export const createMinorUnit = (value: number): MinorUnit => value * 100

/**
 * Convert MinorUnit back to normal value: `minorUnit / 100`.
 * @remarks
 * This reverses the formatting used for payment services.
 * @example
 * ```ts
 * {
 *   unitPrice: convertMinorUnitToValue(500000), // 5000, converts back to 5000.00 SEK
 *   discountPercent: convertMinorUnitToValue(1000), // 10, converts back to 10.00%
 *   vatRate: convertMinorUnitToValue(2500), // 25, converts back to 25.00%
 * }
 * ```
 */
export const convertMinorUnitToValue = (minorUnit: MinorUnit): number => minorUnit / 100

/**
 * Calculate the tax amount based on the total amount and the tax rate.
 *
 * @param totalAmount - The total amount (e.g., price or revenue) on which tax is calculated.
 * @param taxRate - The tax rate to be applied. This value can be in major units (e.g., 25 for 25%) or minor units (e.g., 2500 for 25.00%), depending on `isMinorUnit`.
 * @param isMinorUnit - A boolean flag indicating if the `totalAmount` and `taxRate` are in minor units (multiplied by 100 for services that require this format). If true, both `totalAmount` and `taxRate` should be passed in minor units.
 *
 * @returns The calculated tax value based on the provided total amount and tax rate.
 *
 * @remarks
 * If `isMinorUnit` is set to true, both `totalAmount` and `taxRate` are assumed to be in minor units (e.g., for payment services that require prices to be multiplied by 100).
 *
 * @example
 * ```ts
 * // Example 1: Major units (default)
 * const totalAmount = 5000; // 5000 SEK
 * const taxRate = 25; // 25% VAT
 * const tax = getTax(totalAmount, taxRate) // 1000;
 *
 * // Example 2: Minor units
 * const totalAmountMinor = createMinorUnit(5000); // 500000, for 5000.00 SEK
 * const taxRateMinor = createMinorUnit(25); // 2500, for 25% VAT
 * const taxMinor = getTax(totalAmountMinor, taxRateMinor, true); // 100000 (for 1000 SEK in minor units)
 * ```
 */
export const getTax = (totalAmount: number, taxRate: number, isMinorUnit?: boolean) => {
  const percents = isMinorUnit ? createMinorUnit(100) : 100
  return totalAmount - (totalAmount * percents) / (percents + taxRate)
}
