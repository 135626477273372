import { type AppConfigBase, type PathObj, type PathType } from 'ecosystem'

export const constructURLWithParams = (
  baseUrl: string,
  queryParams: Record<string, string | undefined | null>
) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(queryParams)) {
    if (value && value.trim() !== '') {
      params.append(key, value)
    }
  }

  return params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl
}

export const isExternalLink = (url: string): boolean => {
  const externalPattern =
    /^(?:https?:\/\/|mailto:|tel:|ftp:\/\/|ftps:\/\/|file:\/\/|news:\/\/|gopher:\/\/|ws:\/\/|wss:\/\/|www\.)/i
  return externalPattern.test(url)
}

export const normalizeHref = (url: string, external: boolean | undefined) => {
  if (external && !/^https?:\/\//i.test(url)) {
    return `https://${url}`
  }
  return url
}

export const hrefFromPathObj = (
  obj: PathObj,
  pathMap: Record<Exclude<PathType, 'custom'>, string>
) => {
  if (obj.type === 'custom') {
    return obj.slug
  }
  return `${pathMap[obj.type]}/${obj.slug}`
}

export const hrefFromAppPaths = (obj: PathObj, appPaths: AppConfigBase['paths'] | undefined) => {
  return hrefFromPathObj(obj, {
    category: appPaths?.categoriesUrlPath || 'example-category-path',
    brand: appPaths?.brandsUrlPath || 'example-brand-path',
    tag: appPaths?.tagsUrlPath || 'example-tag-path',
    blog: appPaths?.blogsUrlPath || 'example-blog-path'
  })
}

/**
 * Resolves a relative or absolute subpath against a base URL or string and returns the resulting URL as a string.
 *
 * @param base - The base URL or string to resolve against. This should be a valid URL or URL-like string.
 * @param subPath - The subpath or URL to resolve. It can be a relative path or a full URL.
 * @returns A string representing the resolved URL.
 *
 * @example
 * // Resolving a relative path against a base URL
 * const resolved = resolveUrlPath('https://example.com', '/terms');
 * console.log(resolved); // Output: 'https://example.com/terms'
 *
 * @example
 * // Resolving a URL against another base URL
 * const resolved = resolveUrlPath('https://example.com', 'https://another.com/terms');
 * console.log(resolved); // Output: 'https://another.com/terms'
 */
export const resolveUrlPath = (base: string | URL, subPath: string | URL): string => {
  return new URL(subPath, base).toString()
}
