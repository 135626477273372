'use client'
import type { Dispatch, FC, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { useMediaQuery, useTheme } from '@chakra-ui/react'
import type { CarouselSliderProps } from './CarouselSlider'
import CarouselSlider from './CarouselSlider'
import type { CarouselTrackProps } from './CarouselTrack'
import CarouselTrack from './CarouselTrack'
import type { CarouselItemProps } from './CarouselItem'
import CarouselItem from './CarouselItem'
import { CAROUSEL_ITEMS, type CarouselItemSize } from './constants'

export interface CarouselComponentProps {
  gap: number
  children: any[]
  carouselItemsByBreakpoint?: CarouselItemSize
  showItemCount?: boolean
  showCarouselProgress?: boolean
  activeItem: number
  setActiveItem: Dispatch<SetStateAction<number>>
  containerProps?: Omit<BoxProps, 'gap' | 'children'>
  itemContainerProps?: Omit<BoxProps, 'gap' | 'children'>
}

const CarouselComponent: FC<CarouselComponentProps> = ({
  showItemCount,
  children,
  gap,
  carouselItemsByBreakpoint,
  activeItem,
  setActiveItem,
  containerProps,
  showCarouselProgress,
  itemContainerProps
}) => {
  const [trackIsActive, setTrackIsActive] = useState(false)
  const [multiplier, setMultiplier] = useState(0.35)
  const [sliderWidth, setSliderWidth] = useState(0)
  const [constraint, setConstraint] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)

  const initSliderWidth = useCallback((width: number) => {
    setSliderWidth(width)
  }, [])

  const positions = useMemo(
    () => children.map((_, index) => -Math.abs((itemWidth + gap) * index)),
    [children, itemWidth, gap]
  )

  const { breakpoints } = useTheme()

  const [isBetweenBaseAndMd] = useMediaQuery(
    `(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.md})`
  )

  const [isBetweenMdAndXl] = useMediaQuery(
    `(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`
  )

  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`)

  useEffect(() => {
    if (isBetweenBaseAndMd) {
      setItemWidth(sliderWidth - (carouselItemsByBreakpoint?.sm || CAROUSEL_ITEMS.sm) - gap)
      setMultiplier(0.65)
      setConstraint(carouselItemsByBreakpoint?.sm || CAROUSEL_ITEMS.sm)
    }
    if (isBetweenMdAndXl) {
      setItemWidth(sliderWidth / (carouselItemsByBreakpoint?.md || CAROUSEL_ITEMS.md) - gap)
      setMultiplier(0.5)
      setConstraint(carouselItemsByBreakpoint?.md || CAROUSEL_ITEMS.md)
    }
    if (isGreaterThanXL) {
      setItemWidth(sliderWidth / (carouselItemsByBreakpoint?.lg || CAROUSEL_ITEMS.lg) - gap)
      setMultiplier(0.35)
      setConstraint(carouselItemsByBreakpoint?.lg || CAROUSEL_ITEMS.lg)
    }
  }, [
    isBetweenBaseAndMd,
    isBetweenMdAndXl,
    isGreaterThanXL,
    sliderWidth,
    gap,
    carouselItemsByBreakpoint
  ])

  const sliderProps: Omit<CarouselSliderProps, 'children'> = {
    setTrackIsActive,
    initSliderWidth,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    gap,
    ...containerProps
  }

  const trackProps: Omit<CarouselTrackProps, 'children'> = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    activeItem,
    constraint,
    multiplier,
    itemWidth,
    positions
  }

  const itemProps: Omit<CarouselItemProps, 'children' | 'index'> = {
    setTrackIsActive,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    gap,
    ...itemContainerProps
  }

  return (
    <CarouselSlider {...sliderProps} {...{ showItemCount, showCarouselProgress }}>
      <CarouselTrack {...trackProps}>
        {children.map((child, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key -- We don't have a dynamic key
            <CarouselItem key={index} {...itemProps} {...{ index }}>
              {child}
            </CarouselItem>
          )
        })}
      </CarouselTrack>
    </CarouselSlider>
  )
}

export default CarouselComponent
