export interface CarouselItemSize {
  sm: number
  md: number
  lg: number
}

export const CAROUSEL_ITEMS: CarouselItemSize = {
  sm: 1,
  md: 3,
  lg: 6
}
