import { isNullable } from './general'

/**
 * Format a number or string by adding comma separators for thousands, with optional control over decimal places.
 * @remarks
 * This function formats numbers with commas to make large numbers easier to read, e.g., `1000000` becomes `1,000,000`.
 * It also allows specifying the maximum number of decimal places to retain.
 * If the input is already a string, the function returns it unchanged.
 * It doesn't round en original number.
 *
 * @param x - The input value to be formatted, can be a number or a string.
 * @param maximumFractionDigits - The maximum number of decimal places to retain. Defaults to `0`.
 * @returns The formatted number with comma separators and specified decimal places, or the original string if the input was not a number.
 *
 * @example
 * ```ts
 * // Basic usage with numbers
 * commaSeparator(1234567); // "1,234,567"
 * commaSeparator(1234.56); // "1,234"
 *
 * // Specifying decimal places
 * commaSeparator(1234.567, 2); // "1,234.56"
 * commaSeparator(12.456, 2); // "12.45"
 * commaSeparator(12.7, 2); // "12.7"
 * commaSeparator(12, 2); // "12"
 *
 * // String input
 * commaSeparator("1234"); // "1234" (no changes for string input)
 * ```
 */
export const commaSeparator = (x: string | number, maximumFractionDigits = 0): string => {
  if (typeof x === 'number') {
    const factor = Math.pow(10, maximumFractionDigits)
    const truncated = Math.trunc(x * factor) / factor // Use Math.trunc for truncation
    // Special case: very small numbers should be truncated to zero
    if (Math.abs(truncated) < 1 / factor) {
      return '0'
    }
    return truncated.toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits
    })
  }
  return x
}

/**
 * Calculate the percentage of one number relative to another.
 * @remarks
 * This function computes what percentage `x` is of `y` by dividing `x` by `y` and multiplying by 100.
 * Essentially, it answers the question: "What percent of `y` is `x`?".
 *
 * @param x - The part or portion value.
 * @param y - The whole or total value.
 * @returns The percentage value representing how much `x` is of `y`.
 *
 * @example
 * ```ts
 * percentage(50, 200); // 25 (because 50 is 25% of 200)
 * percentage(1, 100); // 1 (1% of 100)
 * percentage(30, 60); // 50 (because 30 is 50% of 60)
 * ```
 */
export const percentage = function (x: number, y: number) {
  return 100 / (y / x)
}

/**
 * Rounds a number to a specified number of decimal places.
 * @remarks
 * This function rounds a given number down (using `Math.floor`) to a precision defined by the `maxFractionDigit` parameter.
 * It effectively truncates the number rather than rounding it in the traditional sense (no rounding up).
 *
 * @param value - The number to be rounded.
 * @param maxFractionDigit - The maximum number of decimal places to round to (default is 2).
 * @returns The rounded number, with at most the specified number of decimal places.
 *
 * @example
 * ```ts
 * roundNumber(123.4567); // 123.45 (rounded down to 2 decimal places)
 * roundNumber(123.4567, 3); // 123.456 (rounded down to 3 decimal places)
 * roundNumber(123); // 123 (no change since it's already an integer)
 * ```
 */
export const roundNumber = (value: number, maxFractionDigit = 2) => {
  const multiplier = Math.pow(10, maxFractionDigit)

  return Math.floor(value * multiplier) / multiplier
}

export const parseIntSafe = (value?: string | number | null): number | undefined => {
  if (isNullable(value)) {
    return undefined
  }

  if (typeof value === 'number') {
    return Number.isNaN(value) ? undefined : value
  }

  const parsedValue = Number.parseInt(value)

  return Number.isNaN(parsedValue) ? undefined : parsedValue
}
